export const ourServicesData = [
  { service: "Brand Positioning" },
  { service: "Brand Messaging" },
  { service: "Brand Identity Design" },
  { service: "Brand Naming" },
  { service: "Label/Packaging Design" },
  { service: "Digital Marketing" },
  { service: "Illustration" },
  { service: "Website Development" }
]
